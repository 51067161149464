<template>
  <div>
    <!-- 头部 -->
    <van-cell center>
      <!-- 使用 title 插槽来自定义标题 -->
      <template slot="title">
        <span class="custom-title">{{ PatientName }}</span>
      </template>
      <!-- <template slot="label">
        <span>{{ CardNumber }}</span>
      </template> -->
      <template slot="default">
        <span @click="onqiehuan">切换用户</span>
      </template>
      <van-icon
        slot="right-icon"
        class="iconfont"
        class-prefix="icon"
        name="qiehuan"
        color="#57c4b7"
        @click="onqiehuan"
      />
    </van-cell>
    <van-cell>
      <template slot="title">
        <span style="color: #57c4b7"
          >*温馨提示：微信医院仅能查询3个月内的记录，如需查询更早以前请到窗口查询。</span
        >
      </template>
    </van-cell>
    <!-- 中间 -->

    <!-- <van-field :value="startdate" @click="showstart = true" placeholder="开始日期" style="font-size: 12px; width: 26%;" />
      <van-cell title="至" style="width: 12%;text-align: center;" />
      <van-field :value="enddate" @click="showend = true" placeholder="结束日期" style="font-size: 12px; width: 26%;float: left;text-align: center;" />
      <van-cell title="日期排序" style="width: 36%;">
        <van-icon slot="right-icon" class="iconfont" class-prefix='icon' name="caozuo-paixu-jiangxu" color="#57c4b7" />
      </van-cell>-->
    <van-cell center style="padding: 0 16px; margin: 5px 5px 0 5px">
      <!-- <template slot="icon" >
            <van-field
            :value="startdate"
            @click="show = true"
            placeholder="开始日期"
            style="width:25%; text-align: center;"
          />
          <span style="margin-right: 5%;">至</span>
        </template> -->
      <template slot="title">
        <!-- van-field
           < :value="enddate"
            @click="show = true"
            placeholder="结束日期"
          /> -->
        <span @click="show = true">{{ startdate }}至{{ enddate }}</span>
      </template>
      <template slot="right-icon">
        <van-dropdown-menu>
          <van-dropdown-item
            v-model="value1"
            :options="option1"
            @change="qFOrdInfoDrop"
          />
        </van-dropdown-menu>
      </template>
    </van-cell>

    <!-- <van-calendar v-model="show" type="range" @confirm="onConfirm" :show-confirm="false" /> -->
    <van-calendar
      v-model="show"
      type="range"
      @confirm="onConfirm"
      :defaultDate="defaultDate"
      :min-date="minDate"
      :max-date="maxDate"
      color="#57c4b7"
    />
    <!-- 列表 -->
    <van-list>
      <van-cell-group v-for="item in lists" :key="item + 'payl'">
        <van-cell
          v-bind="item"
          @click="onclickOrder(item.regNo)"
        >
          <template slot="title">
            <span style="font-size: 16px">{{ item.deptName }}</span>
            <span style="color: #57c4b7"> | </span>
            <span style="font-size: 12px">{{ item.drName }}</span>
            <span style="color: #57c4b7"> | </span>
            <span
              v-if="item.stateDescript == '未支付'"
              style="color: red; font-size: 12px"
              >未支付</span
            >
            <span
              v-else-if="item.payStatus == 1"
              style="color: #57c4b7; font-size: 12px"
              >已支付</span
            >
            <span v-else style="font-size: 12px">已退款</span>
          </template>
          <template slot="label">
            <span style="font-size: 14px">缴费时间：</span>
            <span style="color: #57c4b7; font-size: 14px">{{
              item.operTime
            }}</span>
          </template>
          <template slot="right-icon">
            <span style="color: #57c4b7; text-align: center">
              <p>¥{{ item.totalFee }}</p>
            </span>
          </template>
        </van-cell>
        <!-- <van-cell v-bind="item" v-else @click="onclickOrder(item.regNo)">
              <template slot="title">
                <span style="font-size:16px;">{{item.deptName}}</span>
                <span style="color:#57c4b7;"> | </span>
                <span style="font-size:12px;">{{item.drName}}</span>
                <span style="color:#57c4b7;"> | </span>
                <span style="color:#57c4b7;font-size:12px;">已支付</span>
              </template>
              <template slot="label">
                <span style="font-size:14px;">就诊时间：</span>
                <span style="color:#57c4b7; font-size:14px;">{{item.operTime}}</span>
              </template>
              <template slot="right-icon">
                <span style="color:#57c4b7; text-align:center;">
                  <p>¥{{item.sumfee}}</p>
                </span>
              </template>
           </van-cell> -->
      </van-cell-group>
    </van-list>
    <div
      style="
        font-size: 12px;
        color: #d3d3d3;
        margin: 1% 40%;
        width: auto;
        height: 40px;
      "
    >
      <span>&copy;拓实银医</span>
    </div>
  </div>
</template>
<script>
import store from "../../store";
import ajax from "../../lib/ajax";
import { Toast } from "vant";
var nowDate = new Date();
var listss = [];
export default {
  data() {
    return {
      show: false,
      startdate: "",
      enddate: "",
      startdates: "",
      enddates: "",
      minDate: new Date(
        nowDate.getFullYear(),
        nowDate.getMonth() - 3,
        nowDate.getDate()
      ),
      maxDate: new Date(),
      defaultDate: [],
      PatientName: "",
      CardNumber: "",
      PayStatus: "",
      Idcard: "",
      value1: 0,
      lists: [],
      option1: [
        { text: "默认排序", value: 0 },
        { text: "时间排序", value: 1 },
        { text: "价格排序", value: 2 },
      ],
      list: [],
      orderId: 0,
      title: "",
      fee: "",
    };
  },
  created() {
    this.PatientName = store.getters["store/userName"];
    this.CardNumber = store.getters["store/cardNo"]; //声明就诊卡号

    const lastDate = new Date(new Date().setDate(new Date().getDate() - 7));
    const date = [lastDate, new Date()];
    this.onConfirm(date);
    this.defaultDate = date;

    // this.onLoad();
  },
  methods: {
    //切换就诊卡
    onqiehuan() {
      this.$router
        .replace({
          path: "/QFCardL",
        })
        .catch((err) => {
          console.log(err);
        });
    },
    formatDate1(date) {
      return (
        `${date.getFullYear()}` +
        "/" +
        `${
          date.getMonth() >= 9
            ? date.getMonth() + 1
            : "0" + (date.getMonth() + 1)
        }` +
        "/" +
        `${date.getDate() >= 9 ? date.getDate() : "0" + date.getDate()}`
      );
    },
    formatDate(date) {
      return (
        `${date.getFullYear()}` +
        "/" +
        `${
          date.getMonth() >= 9
            ? date.getMonth() + 1
            : "0" + (date.getMonth() + 1)
        }` +
        "/" +
        `${date.getDate() >= 9 ? date.getDate() : "0" + date.getDate()}`
      );
    },
    onConfirm(date) {
      // console.log(date);
      const [start, end] = date;
      this.show = false;
      this.startdate = `${this.formatDate1(start)}`;
      this.enddate = `${this.formatDate1(end)}`;
      this.startdates = `${this.formatDate(start)}`;
      this.enddates = `${this.formatDate(end)}`;
      this.onLoad();
    },
    //change下拉菜单时间
    qFOrdInfoDrop() {
      this.lists = [];
      // this.getlistsDate();
      if (this.value1 === 0) {
        // console.log(this.list);
        this.onLoad();
      } else if (this.value1 === 1) {
        this.list.sort(this.sortTime);
      } else if (this.value1 === 2) {
        this.list.sort(this.sortMoney);
      } else {
        this.$toast("出错了111");
      }
    },
    //时间排序
    sortTime(obj1, obj2) {
      return obj2.operTime - obj1.operTime;
    },
    //价格排序
    sortMoney(obj1, obj2) {
      return obj2.sumfee - obj1.sumfee;
    },
    //获取时间筛选数据
    // getlistsDate() {
    //   // alert(listss);
    //   for (let i = 0; i < listss.length; i++) {
    //     if (
    //       this.DateContrast(this.startdates, listss[i].operTime) &&
    //       this.DateContrast(listss[i].operTime, this.enddates)
    //     ) {
    //       let index = listss[i];
    //       console.log(index);
    //       this.lists.push(index);
    //     }
    //   }
    // },
    //时间区间比较
    // DateContrast(start, end, flags) {
    //   console.log(start, end);
    //   const starttime = new Date(
    //     start.substring(0, 4),
    //     start.substring(5, 7),
    //     start.substring(8, 10)
    //   );
    //   const starttimes = starttime.getTime();
    //   const endTime = new Date(
    //     end.substring(0, 4),
    //     end.substring(5, 7),
    //     end.substring(8, 10)
    //   );
    //   const endTimes = endTime.getTime();
    //   if (endTimes >= starttimes) {
    //     flags = true;
    //     return flags;
    //   } else {
    //     flags = false;
    //     return flags;
    //   }
    // },
    async onLoad() {
      // let idcard = store.getters["store/idcard"];
      let userId = store.getters["store/userId"];
      console.log(userId);
      // idcard = "0001052852";
      await ajax
        .post(
          "/Api/FeeService/GetFeeLog",
          {
            userID: userId,
            beginDate: this.startdate + " 00:00:00",
            endDate: this.enddate + " 23:59:59",
          }
          // idcard +
          // "&BeginDate=" +
          //  +
          // &EndDate=" +
          // this.enddate +
          // " 23:59:59"
        )
        .then((rep) => {
          this.lists = [];
          if (rep.data.result.resultCode !== "-1") {
            listss = rep.data.result.clinicFees;
            console.log(rep.data.result);
            if (listss.length != 0) {
              this.lists=listss;
              // console.log(this.lists);
              // this.qFOrdInfoDrop();
              // for (let i = 0; i < listss.length; i++) {
              //   const el = listss[i].sumfee;
              //   this.FeeSum += el;
              //   // console.log(sum);
              // }
            } else {
              Toast("该用户暂无缴费记录数据");
            }
          } else {
            Toast("该用户暂无缴费记录数据");
          }
        })
        .catch((err) => {
          console.log(err.message);
          // console.log(err);
          // Dialog.alert({
          //   title: "错误",
          //   message:
          Toast("系统错误,请稍后重试");
          // });
        });
    },
    onclickOrder(regNo) {
      // alert(regNo);
      window.sessionStorage.setItem("startdate", this.startdate);
      window.sessionStorage.setItem("enddate", this.enddate);
      // window.sessionStorage.setItem("recipeNO", recipeNO);
      store.commit("store/setRegNo", regNo);
      this.$router
        .replace({
          path: "/QFOrdeIT",
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>

<style lang="less" scoped>
.login_container {
  height: 100%;
  // display: initial;
}
.van-field {
  padding: 0;
  margin: 0;
}
</style>
